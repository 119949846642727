import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import SearchTest from "~/components/SearchTest";

const styles = {
	list: {
		width: 250
	},
	fullList: {
		width: "auto"
	}
};

class TempSearchDrawer extends React.Component {
	state = {
		top: false,
		left: false,
		bottom: false,
		right: false
	};

	toggleDrawer = (side, open) => () => {
		this.setState({
			[side]: open
		});
	};

	render() {
		// const { classes, searchcontent } = this.props;

		return (
			<div>
				<Button onClick={this.toggleDrawer("bottom", true)}>
					<SearchIcon color="secondary" />
				</Button>

				<Drawer
					anchor="bottom"
					open={this.state.bottom}
					onClose={this.toggleDrawer("bottom", false)}
					classes={{
						paperAnchorBottom: "h-90vh w-full"
					}}
				>
					<div tabIndex={0} role="button">
						<div className="h-90vh overflow-y-scroll">
							<SearchTest />
						</div>
					</div>
				</Drawer>
			</div>
		);
	}
}

TempSearchDrawer.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TempSearchDrawer);
