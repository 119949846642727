module.exports = {
	siteTitle: "Dgatsbo",
	pathPrefix: "/dgatsbo",
	hostname: "localhost:8000", // no trailing slash (used to create xml sitemap)
	postsPerHomePage: 7, // Number of posts shown on the 1st page of of the index.js template (home page)
	postsPerPage: 12, // Number of posts shown on paginated pages
	title: `DUMBO`,
	description: `This is the most comprehensive website about the Dumbo neighborhood in Brooklyn, full of event listings, businesses and creative people.`,
	titleTemplate: "%s · DUMBO",
	url: "https://www.dumbo.is", // No trailing slash allowed!
	image: "/img/dumbo.png", // Path to your image you placed in the 'static' folder
	twitterUsername: "@dumbobid",
	author: `@dumbobid`,
	algoliaId: "RKSBSZ20O6",
	algoliaApiKey: "2b6ae59832a449b55853b3386bfcd9a7",
	manifest: {
		name: "Dumbo",
		short_name: "hello!",
		start_url: "/",
		background_color: "#181818",
		theme_color: "#E9E9E9",
		display: "minimal-ui",
		icon: "static/img/favicon.png" // This path is relative to the root of the site.
	}
};

// algoliaId: "JF4WG1QIUU",
// algoliaApiKey: "8b2a2fd3af75fc9ec844ef067ef4e89c",
