import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
// import MenuIcon from "@material-ui/icons/Menu";
import { MdMenu } from "react-icons/md";

// import { createMuiTheme } from "@material-ui/core/styles";

// const theme = createMuiTheme({
// 	palette: {
// 		primary: {
// 			light: "#757ce8",
// 			main: "#333333",
// 			dark: "#002884",
// 			contrastText: "#939393"
// 		},
// 		secondary: {
// 			light: "#ff7961",
// 			main: "939393",
// 			dark: "#ba000d",
// 			contrastText: "#000"
// 		}
// 	}
// });

const styles = {
	list: {
		width: 250
	},
	fullList: {
		width: "auto"
	}
};

class TempNavDrawer extends React.Component {
	state = {
		top: false,
		left: false,
		bottom: false,
		right: false
	};

	toggleDrawer = (side, open) => () => {
		this.setState({
			[side]: open
		});
	};

	render() {
		const { linkslist } = this.props;

		return (
			<div>
				<Button
					color="primary"
					className="bg-pink"
					onClick={this.toggleDrawer("left", true)}
					disableFocusRipple={true}
					disableRipple={true}
				>
					<MdMenu
						size={"2rem"}
						className={"text-grey hover:text-grey-light trans"}
					/>
				</Button>

				<Drawer
					open={this.state.left}
					onClose={this.toggleDrawer("left", false)}
					classes={{
						paperAnchorLeft: "w-90vw md:w-75vw lg:w-40vw cardboard"
					}}
				>
					<div
						tabIndex={0}
						role="button"
						onClick={this.toggleDrawer("left", false)}
						onKeyDown={this.toggleDrawer("left", false)}
					>
						<div>{linkslist}</div>
					</div>
				</Drawer>
			</div>
		);
	}
}

TempNavDrawer.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TempNavDrawer);

// <MdMenu size={"2rem"} color={"#ff1d25"} />
