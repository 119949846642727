import React from "react";
import { TiWeatherCloudy } from "react-icons/ti";
import Clock from "react-live-clock";

const WeatherBox = props => {
	return (
		<div className="flex flex-wrap -mx-4 mb-4 items-center">
			<div className="w-1/2 px-4 flex flex-1 w-full text-center items-center">
				<TiWeatherCloudy size={"4rem"} className={"mb-2"} />{" "}
				<h4 className="font-dincond uppercase text-5xl leading-zero mb-0 pl-2">
					71°
				</h4>
			</div>
			<div className="w-1/2 px-4 flex flex-1 w-full text-center items-center">
				<h4 className="font-dincond uppercase text-2xl">
					The time now in dumbo is&nbsp;
					<Clock format={"h:mm a"} ticking={true} />
				</h4>
			</div>
		</div>
	);
};

export default WeatherBox;
