/* global tw */
import React from "react";
import { Link } from "gatsby";
// import Headroom from "react-headroom";
import logo from "../../images/dumbo-ko.png";
// import NavDrawer from "../Drawer/NavDrawer";
import NavDrawer from "../Drawer/NavDrawerToo";
import SearchDrawer from "../Drawer/SearchDrawer";
import SearchIcon from "@material-ui/icons/Search";
// import Clock from "react-live-clock";
import WeatherBox from "~/components/WeatherBox";
import styled from "styled-components";

// import logo from "../../images/logo.svg"; // Tell Webpack this JS file uses this image
// import FullScreen from "../../components/FullScreen";

// const SocialNetworkList = styled.div`
// 	${tw`block`};
// `;

const NavHolder = styled.div`
	${tw`w-3/4 flex flex-col items-center mx-auto h-screen justify-center`};
`;

// const ListLink = props => (
// 	<Link
// 		className="block mt-4 lg:inline-block lg:mt-0 uppercase text-xs font-semibold tracking-wide trans mr-6 text-white hover:text-grey-light py-6"
// 		activeClassName="text-red"
// 		to={props.to}
// 	>
// 		{props.children}
// 	</Link>
// );

const SideLink = props => (
	<li>
		<Link
			className="block py-4 text-grey hover:text-grey-light trans font-bold text-xs uppercase"
			activeClassName="text-grey-lightest"
			to={props.to}
		>
			{props.children}
		</Link>
	</li>
);

const DrawerLink = props => (
	<Link
		className="block uppercase text-3xl font-dincond font-black trans text-black hover:text-grey-light py-4"
		activeClassName="text-cb-red"
		to={props.to}
	>
		{props.children}
	</Link>
);

const Header = ({ siteTitle }) => (
	<header>
		<nav className="flex items-center justify-between flex-wrap md:flex-col py-3 px-6 md:px-0 h-16 md:h-screen w-full md:w-24 font-din bg-grey-darkest relative md:fixed z-50">
			<div className="flex items-center md:items-stretch flex-grow md:flex-no-shrink text-white flex-col">
				<Link
					to="/"
					className="text-white hover:text-grey uppercase w-16 md:w-20 mx-auto md:mx-0 block"
				>
					<img src={logo} alt="Logo" />
				</Link>
				<div className="hidden md:flex flex-grow items-end text-center mb-8 pb-8">
					<ul className="list-reset my-8 w-full">
						<SideLink to="/blogging">News</SideLink>
						<SideLink to="/">Events</SideLink>
						<SideLink to="/places">Listings</SideLink>
						<SideLink to="/directory">Directory</SideLink>
						<SideLink to="/">Visit</SideLink>
						<SideLink to="/">VIP</SideLink>
						<SideLink to="/testing-five">Map</SideLink>
						<SideLink to="/search">
							<SearchIcon />
						</SideLink>
					</ul>
				</div>
			</div>

			<div className="absolute pin-r md:w-full text-center md:pin-b md:pb-6">
				<NavDrawer
					linkslist={
						<NavHolder>
							<WeatherBox />
							<DrawerLink to="/places">Places</DrawerLink>
							<DrawerLink to="/offices">Offices</DrawerLink>
							<DrawerLink to="/artists">Artists</DrawerLink>
							<DrawerLink to="/exhibits">Exhibits</DrawerLink>
							<DrawerLink to="/blogging">News</DrawerLink>
							<DrawerLink to="/buildings">Buildings</DrawerLink>
							<DrawerLink to="/contact">Contact</DrawerLink>
							<SearchDrawer />
						</NavHolder>
					}
				/>
			</div>
		</nav>
	</header>
);

export default Header;
// <FullScreen />

// <NavDrawer />

// <div className="w-full hidden block text-right flex-grow lg:flex lg:items-center lg:w-auto">
// <div className="text-sm flex items-center ml-auto">
// 	<ListLink to="/places">Places</ListLink>
// 	<ListLink to="/offices">Offices</ListLink>
// 	<ListLink to="/artists">Artists</ListLink>
// 	<ListLink to="/exhibits">Exhibits</ListLink>
// 	<ListLink to="/blogging">News</ListLink>
// 	<ListLink to="/buildings">Buildings</ListLink>
// 	<ListLink to="/contact">Contact</ListLink>
// 	<SearchDrawer />
// </div>
// </div>
