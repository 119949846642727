/* global tw */
import React from "react";
import algoliasearch from "algoliasearch/lite";
import {
	InstantSearch,
	SearchBox,
	Hits,
	Highlight,
	Pagination,
	RefinementList,
	CurrentRefinements,
	ClearRefinements,
	ToggleRefinement,
	Snippet,
	Panel
} from "react-instantsearch-dom";
import styled from "styled-components";

const config = require("../site.config");

// const searchClient = algoliasearch(
// 	"JF4WG1QIUU",
// 	"8b2a2fd3af75fc9ec844ef067ef4e89c"
// );

const searchClient = algoliasearch(config.algoliaId, config.algoliaApiKey);

// const Body = styled.div`
// 	${tw`text-grey mt-2`};
// `;

const Title = styled.h2`
	${tw`font-dincond uppercase text-2xl font-black text-black`};
`;

const RefinementWrapper = styled.div`
	${tw`w-full md:w-1/4 px-4`};

	ul {
		${tw`list-reset`};
	}

	.ais-RefinementList {
		${tw`mt-4`};
	}

	.ais-RefinementList-label,
	.ais-ToggleRefinement-label {
		${tw`font-din font-bold text-xs uppercase tracking-wide block cursor-pointer leading-none pl-2 pb-2 border-b border-black-30`};
		padding-top: 0.65rem;
	}

	.ais-RefinementList-item.ais-RefinementList-item--selected
		.ais-RefinementList-label {
		${tw`bg-black text-white`};
		border-bottom: 1px solid rgba(255, 255, 255, 0.33);
	}

	.ais-RefinementList-label:hover,
	.ais-ToggleRefinement-label:hover {
		background: rgba(255, 255, 255, 0.45);
		transition: all 0.25s;
	}

	.ais-RefinementList-checkbox,
	.ais-ToggleRefinement-checkbox {
		${tw`hidden`};
	}

	.ais-RefinementList-showMore {
		${tw`g-black text-white px-3 py-1 mx-auto text-center  mt-4 inline-block font-din font-bold text-xs uppercase tracking-wide leading-normal`};
	}

	.ais-RefinementList-count {
		display: inline-block;
		padding: 0.25em 0.6em;
		font-weight: 400;
		line-height: 1;
		text-align: center;
		white-space: nowrap;
		vertical-align: baseline;
		font-size: 85%;
		opacity: 0.625;
		cursor: pointer;
	}

	.ais-RefinementList-showMore.ais-RefinementList-showMore--disabled {
		${tw`opacity-25`};
	}

	.ais-CurrentRefinements-label {
		${tw`hidden`};
	}

	.ais-CurrentRefinements-category {
		${tw`inline-block bg-grey-lightest rounded-full px-3 py-1 text-grey-darker`} mr-2;
	}
	.ais-CurrentRefinements-categoryLabel {
		${tw`text-xs uppercase font-semibold mr-1`};
	}

	.ais-CurrentRefinements-delete {
		${tw`text-xs`};
	}

	.ais-ClearRefinements {
		${tw`inline-block bg-grey-darkest rounded-full px-3 py-1`};
	}

	.ais-ClearRefinements-button {
		${tw`text-grey-light text-xs uppercase font-semibold`};
	}

	.ais-CurrentRefinements.ais-CurrentRefinements--noRefinement
		+ .ais-ClearRefinements {
		${tw`hidden`};
	}
`;

const HitContainer = styled.div`
	${tw`w-full md:w-3/4 px-4`};

	ul {
		${tw`list-reset`};
	}

	.ais-Hits-list {
		${tw`flex flex-wrap -mx-4 mb-8`};
	}

	.ais-Hits-item {
		${tw`mx-auto w-1/3 flex px-4 mb-6 text-black`};
	}

	.ais-Snippet {
		${tw`text-grey mt-2 block`};
	}
`;

const PaginationWrapper = styled.div`
	ul {
		${tw`list-reset`};
	}

	.ais-Pagination-list {
		${tw`flex list-reset w-auto font-din font-bold text-sm`} justify-center;
	}

	.ais-Pagination-item {
		${tw`block text-black border-r border-grey-light`};
	}

	.ais-Pagination-item:hover {
		${tw`text-white bg-black`};
	}

	.ais-Pagination-link {
		${tw`block px-3 py-2 text-black`};
	}

	.ais-Pagination-link:hover {
		${tw`text-white bg-black`};
	}

	.ais-Pagination-item--selected {
		${tw`block text-white bg-black border-r border-black`};
	}

	.ais-Pagination-item--selected .ais-Pagination-link {
		${tw`text-white bg-black`};
	}

	.ais-Pagination-item--nextPage {
		${tw`.border-none`};
	}
`;

function Product({ hit }) {
	return (
		<div className="card bg-white trans overflow-x-hidden w-full h-full hover:shadow-lg">
			<div className="p-4">
				{hit.logoUrl ? (
					<img
						src={hit.logoUrl}
						className="img-fluid w-16 h-16 float-right pl-2 pb-2"
						alt={hit.title}
					/>
				) : (
					<div className="w-12 h-12 float-right pl-2 pb-2 relative flex bg-grey-lighter rounded-full" />
				)}
				<Title>
					<a href={"/" + hit.uri} className="text-black hover:text-red trans">
						<Highlight attribute="title" hit={hit} />
					</a>
				</Title>
				{hit.body ? (
					<Snippet attribute="body" hit={hit} />
				) : (
					<p>there is nothing written.</p>
				)}
			</div>
		</div>
	);
}

// <div class="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
// <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
// </div>

const App = () => {
	return (
		<div className="alt-container mx-auto">
			<InstantSearch indexName="offices" searchClient={searchClient}>
				<div className="flex flex-wrap -mx-4 md:mb-12">
					<RefinementWrapper>
						<CurrentRefinements />
						<ClearRefinements />
						<SearchBox autoFocus={true} />
						<ToggleRefinement
							attribute="bornInDumbo"
							label="Born in Dumbo"
							value
						/>
						<RefinementList
							attribute="companyIndustry.title"
							limit={10}
							operator="and"
							showMore={true}
							searchable={true}
						/>
						<RefinementList
							attribute="addressSelector"
							limit={100}
							operator="and"
							showMore={false}
						/>
					</RefinementWrapper>
					<HitContainer>
						<Hits hitComponent={Product} />
						<PaginationWrapper>
							<Pagination />
						</PaginationWrapper>
					</HitContainer>
				</div>
			</InstantSearch>
		</div>
	);
};

export default App;

// <div style={{ marginTop: "10px" }}>

// <SortBy
// defaultRefinement="instant_search"
// items={[
//   {
//     label: "Title",
//     value: "title_asc"
//   },
//   {
//     label: "Title desc.",
//     value: "title_desc"
//   },
//   {
//     label: "Company Industry asc.",
//     value: "companyIndustry_title_asc"
//   }
// ]}
// />

// <span className="text-center flex self-center">
// 							<MdBusinessCenter />
// 						</span>

// {hit.body ? (
// 	<Body dangerouslySetInnerHTML={{ __html: hit.body }} />
// ) : (
// 	<p>there is nothing written.</p>
// )}

// <Panel className="w-full md:w-3/4 px-4" footer="Footer" header="Hits">
